import React, { useEffect, useState } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import './flags.css';
import FSC from '../../assets/images/mauritius_2.png';
import FSA from '../../assets/images/saint_2.png';
import SCA from '../../assets/images/UAE Flag.png';


const Flags = ({ updateFlagData, popUp, entityName, entityUpdate }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const ptrParam = searchParams.get('ptr');
  const entityParam = searchParams.get('entity');
  const platform = searchParams.get('platform');
  const utm = searchParams.get('utm_medium')

  const [buttonText, setButtonText] = useState('FSC');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(FSC);
  const entityChnage = searchParams.get('?entityChange');
  const [entityTriger, setEntityTriger] = useState(false);

  useEffect(() => {

    if (entityChnage) {
      setEntityTriger(true);
    }

    if (entityParam === 'FSA_MA') {
      setButtonText('FSA_MA');
      updateFlagData('FSA_MA');
      setImageUrl(FSA);
      setDropdownVisible(false);

    } else if (entityParam === 'FSA') {
      setButtonText('FSA');
      updateFlagData('FSA');
      setImageUrl(FSA);
      setDropdownVisible(false);

    } else if (entityParam === 'SCA') {

      setButtonText('SCA');
      updateFlagData('SCA');
      setImageUrl(SCA);
      setDropdownVisible(false);
    } else {
      setButtonText('FSC');
      updateFlagData('FSC');
      setImageUrl(FSC);
      setDropdownVisible(false);
    }

    if (ptrParam && !entityParam) {

      setButtonText('FSA');
      updateFlagData('FSA');
      setImageUrl(FSA);
      setDropdownVisible(false);
    } else if (ptrParam && entityParam) {
      setButtonText(entityParam);
      updateFlagData(entityParam);
      setImageUrl(entityParam === 'FSA' ? FSA : entityParam === 'FSA_MA' ? FSA : entityParam === 'FSC' ? FSC : entityParam === 'SCA' && SCA );
      setDropdownVisible(false);
    }

    if (platform && !ptrParam && entityParam === 'FSA_MA') {
      setButtonText('FSA_MA');
      updateFlagData('FSA_MA');
      setImageUrl(FSA);
      setDropdownVisible(false);
    } else if ((platform && ptrParam && entityParam === 'FSA_MA')) {
      setButtonText('FSA_MA');
      updateFlagData('FSA_MA');
      setImageUrl(FSA);
      setDropdownVisible(false);
    }


  }, [updateFlagData, entityParam, ptrParam, entityChnage, platform])


  const toggleTextAndImage = (itemName) => {
    let newButtonText, newImageUrl;
    entityName(itemName);
    popUp(true);
    console.log('entityChange: ', entityTriger);
    if (entityTriger) {

      switch (itemName) {
        case 'SCA':
          newButtonText = 'SCA';
          newImageUrl = SCA;
          entityUpdate('');
          break;
        case 'FSC':
          newButtonText = 'FSC';
          newImageUrl = FSC;
          entityUpdate('');
          break;
        case 'FSA':
          newButtonText = 'FSA';
          newImageUrl = FSA;
          entityUpdate('');
          break;
        case 'FSA_MA':
          newButtonText = 'FSA_MA';
          newImageUrl = FSA;
          entityUpdate('');
          break;
        default:
          newButtonText = buttonText;
          newImageUrl = imageUrl;
      }
      setButtonText(newButtonText);
      setImageUrl(newImageUrl);
      updateFlagData(newButtonText);
    } else {
      newButtonText = buttonText
      newImageUrl = imageUrl
    }



    //toggleDropdown();
  };

  const toggleDropdown = () => {
    if ((entityParam === 'FSA_MA' || 'FSA') && platform === 'MT5' && utm === 'godotrader_app') {
      setDropdownVisible(false);
    } else if (entityParam === 'FSA_MA' && platform === 'MT5' && utm === 'godoweb_ma') {
      setDropdownVisible(false);
    } else if ((entityParam === 'FSA_MA' && utm === 'godoweb_ma')) {
      setDropdownVisible(false);
    } else if (entityParam !== 'SCA') {
      setDropdownVisible(!dropdownVisible);
    }
  };

  return (
    <div className="changeDropdown">
      <div className="dropdown">
        <button onClick={toggleDropdown} className="dropbtn">
          <img
            src={imageUrl}
            style={{ width: '30px', marginRight: '5px' }}
            alt="Flag"
          />
          {buttonText}
          <IoMdArrowDropdown className="mt-1 ms-1" />
        </button>
        {dropdownVisible && (
          <div id="myDropdown1" className="dropdown-content">

            <div onClick={() => toggleTextAndImage('FSC')}>
              <img
                src={FSC}
                style={{ width: '30px', marginRight: '5px' }}
                alt="Flag"
              />
              FSC
            </div>
            <div onClick={() => toggleTextAndImage('FSA')}>
              <img
                src={FSA}
                style={{ width: '30px', marginRight: '5px' }}
                alt="Flag"
              />
              FSA
            </div>
            {utm && <div onClick={() => toggleTextAndImage('FSA_MA')}>
              <img
                src={FSA}
                style={{ width: '30px', marginRight: '5px' }}
                alt="Flag"
              />
              FSA_MA
            </div>}
            {!ptrParam && <div onClick={() => toggleTextAndImage('SCA')}>
              <img
                src={SCA}
                style={{ width: '30px', marginRight: '5px' }}
                alt="Flag"
              />
              SCA
            </div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Flags;
